import { useState } from 'react';
import './App.css';
import NewTaskForm from './components/NewTaskForm';

function App() {

  const todos = [
    {"id": 1, "description": "take out the trash"},
    {"id": 2, "description": "finish homework"},
    {"id": 3, "description": "walk the dog"},
    {"id": 4, "description": "cook dinner"}
  ]

  //let firstName = "Bryan";
  const [firstName, setFirstName] = useState("bryan");
 
  const changeFirstName = event => {
    //firstName = event.target.value;
    setFirstName(event.target.value);
  }

  return (
    <>
      <NewTaskForm></NewTaskForm>
      <input onChange={changeFirstName} type="text" value={firstName} />
      <h1>{firstName}</h1>
      <ul>
        {
          todos.map(todo => <li>{todo.description}</li>)
        }
      </ul>
    </>
  );
}

export default App;
