import React from 'react'

const NewTaskForm = () => {
  return (
    <div>
        <div>ID: <input type="text"></input>
            Description: <textarea></textarea>
            <button>Create New Task</button>
        </div>
    
    
    </div>
  )
}

export default NewTaskForm